import {Component, inject} from '@angular/core';
import {ActiveUser} from "./types/active-users";
import {UserService} from "./services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Sili Tutela';
  private userService = inject(UserService);

  ngOnInit() {
    this.getLoggedUserData();
  }

  getLoggedUserData() {
    this.userService.getLoggedUser().subscribe({
      next: (user: ActiveUser) => this.userService.setActiveUser(user)
    });
  }
}
