import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Section} from '../types/section';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  constructor() { }

  currentSection = new BehaviorSubject<Section>(Section.REGISTRY);

  getCurrentSection() {
    return this.currentSection.asObservable();
  }

  updateCurrentSection(section: Section) {
    this.currentSection.next(section);
  }
}
