import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SectionService} from 'src/app/services/section.service';
import {Section} from 'src/app/types/section';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {ActiveUser} from 'src/app/types/active-users';
import {Observable} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import {ResponsiveService} from 'src/app/services/responsive.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit{

  @Output() onToggleSidebar: EventEmitter<boolean> = new EventEmitter();

  currentSection : Section = Section.REGISTRY;
  faUser = faUser;
  user$?: Observable<ActiveUser | undefined>;


  constructor(
    private _responsiveService: ResponsiveService,
    private userService: UserService,
    private sectionService: SectionService) {

  }

  ngOnInit(): void {
    this.sectionService.getCurrentSection().subscribe({
      next: (section: Section) => this.currentSection = section
    })

    this.user$ = this.userService.getActiveUser()
  }

  get responsiveService() {
    return this._responsiveService
  }

  toggleSidebar() {
    this.onToggleSidebar.emit()
  }

}
