<!--<div class="login-container container-fluid">-->
<!--  <div class="login-form-container">-->
<!--    <form [formGroup]="loginForm" autocomplete="off" (keydown.enter)="login()">-->
<!--      <div class="form-floating">-->
<!--        <input [disabled]="showOTPField" [readOnly]="showOTPField" id="email" class="form-control" type="text"-->
<!--               [ngClass]="{'is-invalid' : loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty ||loginForm.controls['email'].touched)}"-->
<!--               formControlName="email" [placeholder]="'Email'">-->
<!--        <label for="email" class="form-label">Email</label>-->
<!--      </div>-->

<!--      <div-->
<!--        *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty ||loginForm.controls['email'].touched)">-->
<!--        <div class="mt-1 error-message" *ngIf="loginForm.controls['email']?.errors?.['required']">-->
<!--          Campo obbligatorio-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="form-floating password-container">-->
<!--        <input-->
<!--          #passwordInput-->
<!--          [disabled]="showOTPField"-->
<!--          [readOnly]="showOTPField"-->
<!--          id="password"-->
<!--          [ngClass]="{'is-invalid' : loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty ||loginForm.controls['email'].touched)}"-->
<!--          class="form-control"-->
<!--          [type]="isPasswordVisible ? 'text' : 'password'"-->
<!--          formControlName="password"-->
<!--          [placeholder]="'Password'">-->
<!--        <label for="password" class="form-label">Password</label>-->

<!--        <div class="password-toggle" (click)="isPasswordVisible = !isPasswordVisible">-->
<!--          <ng-container *ngIf="!isPasswordVisible; else passwordVisible">-->
<!--            <fa-icon [icon]="faEye"></fa-icon>-->
<!--          </ng-container>-->
<!--          <ng-template #passwordVisible>-->
<!--            <fa-icon [icon]="faEyeSlash"></fa-icon>-->
<!--          </ng-template>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div-->
<!--        *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty ||loginForm.controls['password'].touched)">-->
<!--        <div class="mt-1 error-message" *ngIf="loginForm.controls['password']?.errors?.['required']">-->
<!--          Campo obbligatorio-->
<!--        </div>-->
<!--      </div>-->

<!--      <div *ngIf="showOTPField">-->
<!--        <div class="form-floating">-->
<!--          <input id="otp"-->
<!--                 [ngClass]="{'is-invalid' : loginForm.controls['otp'].invalid && (loginForm.controls['otp'].dirty ||loginForm.controls['otp'].touched)}"-->
<!--                 class="form-control" type="text" inputmode="numeric" formControlName="otp" [placeholder]="'OTP'">-->
<!--          <label for="otp" class="form-label">OTP</label>-->
<!--        </div>-->

<!--        <div-->
<!--          *ngIf="loginForm.controls['otp'].invalid && (loginForm.controls['otp'].dirty ||loginForm.controls['otp'].touched)">-->
<!--          <div class="mt-1 error-message" *ngIf="loginForm.controls['otp']?.errors?.['invalidOtp']">-->
<!--            {{ loginForm.controls['otp'].errors?.['message'] }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <button class="btn btn-lg form-button" role="submit" (click)="login()" type="button">Accedi</button>-->

<!--    </form>-->
<!--  </div>-->
<!--</div>-->


<div class="login-container container-fluid">
  <div class="login-form-container">

    <div class="row login-form-row">

      <div class="col-12 d-flex justify-content-center">
        <h3>Login</h3>
      </div>

      <div class="col-12">
        <img src="/assets/img/logo.png" class="img-fluid pb-4" alt="">
      </div>

      <form [formGroup]="loginForm" autocomplete="off" (keydown.enter)="login()">
        <div class="form-floating">
          <input
            [disabled]="showOTPField"
            [readOnly]="showOTPField"
            [ngClass]="{'is-invalid' : loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty ||loginForm.controls['email'].touched)}"
            id="email"
            class="form-control"
            type="text"
            formControlName="email"
            [placeholder]="'Email'">
          <label for="email" class="form-label">Email</label>
        </div>

        <div class="form-floating password-container">
          <input
            #passwordInput
            [disabled]="showOTPField"
            [readOnly]="showOTPField"
            [ngClass]="{'is-invalid' : loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty ||loginForm.controls['password'].touched)}"
            id="password"
            class="form-control"
            [type]="isPasswordVisible ? 'text' : 'password'"
            formControlName="password"
            [placeholder]="'Password'">
          <label for="password" class="form-label">Password</label>

          <div class="password-toggle" (click)="isPasswordVisible = !isPasswordVisible">
            <ng-container *ngIf="!isPasswordVisible; else passwordVisible">
              <fa-icon [icon]="faEye"></fa-icon>
            </ng-container>
            <ng-template #passwordVisible>
              <fa-icon [icon]="faEyeSlash"></fa-icon>
            </ng-template>
          </div>
        </div>

<!--        <div class="register d-flex justify-content-end gap-1">-->
<!--          <span>Non sei ancora registrato?</span> <span class="register-route-link" [routerLink]="['/register']">Clicca qui</span>-->
<!--        </div>-->

        <div *ngIf="showOTPField">
            <div class="form-floating">
                <input id="otp" [ngClass]="{'is-invalid' : loginForm.controls['otp'].invalid && (loginForm.controls['otp'].dirty ||loginForm.controls['otp'].touched)}" class="form-control" type="text" inputmode="numeric" formControlName="otp" [placeholder]="'OTP'">
                <label for="otp" class="form-label">OTP</label>
            </div>

            <div *ngIf="loginForm.controls['otp'].invalid && (loginForm.controls['otp'].dirty ||loginForm.controls['otp'].touched)">
                <div class="mt-1 error-message" *ngIf="loginForm.controls['otp']?.errors?.['invalidOtp']">
                    {{ loginForm.controls['otp'].errors?.['message'] }}
                </div>
            </div>

        </div>

        <button class="btn btn-lg form-button" role="submit" (click)="login()" type="button">Accedi</button>

      </form>
    </div>
  </div>
</div>



