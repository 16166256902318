// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app_id: "sili-fam",
  //siliFamBackend: "/app-sili-fam/api",
  storageBackend: "/storage/api",
  siliFamBackend: "/api",
  printGeneratorBackend: "/app-sili-fam-print-generator/api"
};
