<aside [ngClass]="{'mobile': responsiveService.deviceMobile()}">
    <div class="app-title">
      <img src="/assets/img/logo.png" class="logo" alt="">
      <div *ngIf="responsiveService.deviceMobile()">
        <button class="close-button" (click)="toggleSidebar()">&times;</button>
      </div>
    </div>
    <ul class="nav" [ngClass]="{ 'open' : isSidebarOpen }">
      <ng-container *ngFor="let section of sections">
        <li [routerLink]="section.path" (click)="setCurrentSection(section.name)">
          <div class="section">
            <div class="name">
                <div *ngIf="!responsiveService.deviceMobile()" class="icon-container">
                    <fa-icon class="section-icon" [icon]="section.icon"></fa-icon>
                </div>
                {{ section.label }}
            </div>
            <fa-icon
                *ngIf="!responsiveService.deviceMobile()"
                class="navigate-icon"
                [icon]="faChevronRight">
            </fa-icon>
          </div>
        </li>
      </ng-container>
        <!-- <li routerLink="/home" (click)="setCurrentSection('home')">
            <div class="section">
                <div class="name">
                    <div class="icon-container">
                        <fa-icon class="section-icon" [icon]="faHouse"></fa-icon>
                    </div>
                    Home
                </div>
                <fa-icon class="navigate-icon" [icon]="faChevronRight"></fa-icon>
            </div>
        </li> -->

<!--        <li routerLink="/registry" (click)="setCurrentSection('anagrafica')">-->
<!--            <div class="section">-->
<!--                <div class="name">-->
<!--                    <div *ngIf="!responsiveService.deviceMobile()" class="icon-container">-->
<!--                        <fa-icon class="section-icon" [icon]="faAddressCard"></fa-icon>-->
<!--                    </div>-->
<!--                    Anagrafica-->
<!--                </div>-->
<!--                <fa-icon -->
<!--                    *ngIf="!responsiveService.deviceMobile()" -->
<!--                    class="navigate-icon" -->
<!--                    [icon]="faChevronRight">-->
<!--                </fa-icon>-->
<!--            </div>-->
<!--        </li>-->

        <li (click)="logout()">
            <div class="section">
                <div class="name">
                    <div *ngIf="!responsiveService.deviceMobile()" class="icon-container">
                        <fa-icon class="section-icon" [icon]="faSignOut"></fa-icon>
                    </div>
                    Logout
                </div>
                <fa-icon
                    *ngIf="!responsiveService.deviceMobile()"
                    class="navigate-icon"
                    [icon]="faChevronRight">
                </fa-icon>
            </div>
        </li>

    </ul>

</aside>
