import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export enum Section {
  REGISTRY = "anagrafica",
  UVMT = "uvmt"
}

export interface MenuSection {
  name: Section;
  path: string;
  label: string;
  icon: IconDefinition;
  children?: MenuSection[];
}
