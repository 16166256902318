<div class="layout-container">
    <div
        class="aside-menu"
        [ngClass]="{
            'mobile': responsiveService.deviceMobile(),
            'open': showSidebar
        }"
    >
        <app-aside-menu
            (onToggleSidebar)="toggleSidebar()">
        </app-aside-menu>
    </div>

    <div
        class="main-container"
        [ngClass]="{'mobile': responsiveService.deviceMobile()}"
    >
        <div class="header">
            <app-navbar
                (onToggleSidebar)="toggleSidebar()"
            ></app-navbar>
        </div>
        <ng-scrollbar class="scrollable-container" [visibility]="'hover'" [disabled]="responsiveService.deviceMobile()">
            <div class="main">
                <router-outlet></router-outlet>
            </div>
        </ng-scrollbar>
    </div>
</div>
