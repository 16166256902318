import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginCredentials } from '../../../app/models/login';
import { AuthenticationService } from '../../../app/services/authentication.service';
import { SwalService } from 'src/app/services/swal.service';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {

  }

  loginForm = new FormGroup({
    email: this.formBuilder.nonNullable.control('', Validators.required),
    password: this.formBuilder.nonNullable.control('', Validators.required),
    otp: this.formBuilder.nonNullable.control('', this.otpValidation.bind(this))
  })

  OTPConfigs = {
    length: 6
  }

  showOTPField = false;
  isPasswordVisible = false;

  faEye = faEye;
  faEyeSlash = faEyeSlash;

  otpValidation(control: AbstractControl): ValidationErrors | null {
    if(this.showOTPField && (control.value === undefined || control.value === null))
      return { invalidOtp: true, message: `L'OTP è un campo obbligatorio` };

    if(this.showOTPField && isNaN(control.value))
      return {invalidOtp: true, message: `L'OTP inserito non è valido`}

    if(this.showOTPField && (control.value.length < 6 || control.value.length > 6))
      return {invalidOtp: true, message: `La lunghezza dell'OTP deve essere di 6 caratteri`}

    return null;
  }

  /**
   * MARKS ALL FORM CONTROLS AS TOUCHED
   * WHEN A FORM GROUP IS FOUND, THE FUNCTION IS CALLED RECURSIVELY
   * TO MARK AS TOUCHED THE FORM CONTROLS
   * INSIDE THE FORM GROUP
   *
   * @param formGroup
   */
    validateAllFormFields(formGroup: FormGroup) : void {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true }); // ! IT TRIGGERS THE VALIDATION
        } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
        }
      });
    }

  login() {

    console.warn(this.loginForm.valid);
    console.warn(this.loginForm);

    if(!this.loginForm.valid) {
      this.validateAllFormFields(this.loginForm);
      throw new Error('Il form contiene degli errori');
    }

    const loginCredentials : LoginCredentials = this.loginForm.getRawValue();

    if(!this.showOTPField) {

      this.authenticationService.login({ email: loginCredentials.email, password: loginCredentials.password }).subscribe({
        next: () => {
          this.showOTPField = true;
        },
        error: (error: any) => this.swalService.showErrorMesssage(error.error.message, "Errore durante il login")
      })
    } else {

      this.authenticationService.generateToken(loginCredentials).subscribe({
        next: () => {
          console.warn('SESSIONE CREATA CON SUCCESSO, ROUTING VERSO LA HOME')
          // TODO: ROUTING VERSO LA HOME
          this.router.navigateByUrl('')
        },
        error: (error: any) => this.swalService.showErrorMesssage(error.error.message, "Errore durante il login")
      })
    }
  }
}
