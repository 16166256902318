import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { canActivateLoginPage } from './guards/login-page-guard.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [canActivateLoginPage],
    component: LoginComponent
  },
  {
    path: "",
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "registry",
        pathMatch: "full"
      },
      // {
      //   path: 'home',
      //   loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
      // },
      {
        path: 'registry',
        loadChildren: () => import('./pages/registry/registry.module').then(m => m.RegistryModule)
      },
      {
        path: 'registry/detail/:id',
        loadChildren: () => import('./pages/registry/registry-detail/registry-detail.module').then(m => m.RegistryDetailModule)
      },
      {
        path: "uvmt",
        loadChildren: () => import("./pages/uvmt/uvmt.module").then(m => m.UvmtModule)
      }
    ]
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
