<nav class="navbar">
    <div *ngIf="responsiveService.deviceMobile()">
        <div class="hamburger" (click)="toggleSidebar()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <ul class="nav-links">
        <li class="section-name">
            {{ currentSection }}
        </li>
        <ng-container *ngIf="!responsiveService.deviceMobile()">
            <ng-container *ngIf="user$ | async as user">
                <li style="padding-right: 16px">
                Bentornato/a, {{ user.realname }}
                </li>
            </ng-container>
        </ng-container>
        <li>

            <div class="profile">
                <fa-icon class="icon" [icon]="faUser"></fa-icon>
            </div>
        </li>
    </ul>
</nav>
